import React from 'react'
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi'

const NavToggle = ({ toggled, className }) => {
    return (
        <div className={className}>
            {toggled ? <HiOutlineMenu style={{ color: 'white'}}/> : <HiOutlineMenuAlt2 style={{ color: 'white'}}/>}
        </div>
    )
}

export default NavToggle
