const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    mustEntryPath: '/store/selection',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    rolesType: {
        SA_REP: 'Sales Representative',
        SUPERVISOR_SA_REP: 'Supervisor Sales Representative'
    }
}

export default appConfig
